@media screen and (max-width: 400px) {
  #buch {
    padding: 20px;
    width: 100%;
  }
  #about,
  #fiveSteps,
  #leitsaetze,
  #app,
  #trailer,
  #referenz,
  #contact,
  #footer {
    width: 100%;
  }

  #happyProdukte {
    width: 100%;
  }
}
